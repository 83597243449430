(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/utils/assets/javascripts/contains-correct-outcome.js') >= 0) return;  svs.modules.push('/components/pool-betting/utils/assets/javascripts/contains-correct-outcome.js');
"use strict";


const containsCorrectOutcome = (betOutcome, resultOutcome) => {
  if (!Array.isArray(betOutcome) || !Array.isArray(resultOutcome)) {
    return false;
  }
  if (betOutcome.length === 0 || resultOutcome.length === 0) {
    return false;
  }
  return JSON.stringify(betOutcome) === JSON.stringify(resultOutcome);
};
setGlobal('svs.components.poolBetting.utils.containsCorrectOutcome', containsCorrectOutcome);

 })(window);